<template>
  <el-main class="simple5-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        線上游泳比賽
      </div>
      <p>2023年2月 </p>
      <div class="image-container">
        <img :src="image" class="image" alt="圖片為參與者每天游泳的紀錄">
        <p class="image_desc">泳不放棄之精神，游泳抗癌提升免疫力</p>
        <p class="image_desc" aria-hidden="true">圖片為參與者每天游泳的紀錄</p>
        <p class="image_desc">(圖片提供：快樂島)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">公益目標</div>
        <div class="content-desc">
          根據調查110年從事水域運動僅占全部運動人口的3.2%，成果顯示游泳具有極大的推廣空間，希望能讓更多民眾願意投入游泳運動，進而帶動利用台灣運動數據回饋與種樹公益減少碳排量，推動更多創新與數據利他價值應用。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動</div>
        <div class="content-desc">
          本計畫協手快樂島、快樂工坊一同辦理「卡洛動泳遠健康線上遊泳比賽」。 
        </div>
        <div class="content-desc">
          於民國111年11月11日至12月26日為期45天，共完成累計1,0575人次及12,6900筆運動生理數據，其中包含滑水次數、划距、划頻、配速、游泳效率、趟數等運動數據，完整數據留存原始資料於卡洛動APP中，並已與運動數據公益平台串接。
        </div>
        <div class="desk-pad-view">
          <div class="image-container">
            <el-row>
              <el-col :span="12">
                <img :src="image2" class="image-100" alt="透過Beker Pro音樂貝殼會記錄游泳動態數據紀錄並且有即時分析功能">
                <p class="image_desc">透過Beker Pro音樂貝殼會記錄游泳動態數據紀錄並且有即時分析功能</p>
                <p class="image_desc">(圖片提供：快樂島)</p>
              </el-col>
              <el-col :span="12">
                <img :src="image3" class="image-100" alt="音樂貝殼可以根據轉身計算趟數換算距離">
                <p class="image_desc">音樂貝殼可以根據轉身計算趟數換算距離</p>
                <p class="image_desc">(圖片提供：快樂島)</p>
              </el-col>
            </el-row>
          </div>
          <div class="image-container">
            <el-row>
              <el-col :span="12">
                <img :src="image4" class="image-100" alt="可以計算划手次數">
                <p class="image_desc">可以計算划水次數</p>
                <p class="image_desc">(圖片提供：快樂島)</p>
              </el-col>
              <el-col :span="12">
                <img :src="image5" class="image-100" alt="運動完畢音樂貝殼會將數據同步到手機APP，讓參與者可以隨時追蹤數據資料">
                <p class="image_desc">運動完畢音樂貝殼會將數據同步到手機APP，讓參與者可以隨時追蹤數據資料</p>
                <p class="image_desc">(圖片提供：快樂島)</p>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="phone-view">
          <div class="image-container">
            <img :src="image2" class="image" alt="">
            <p class="image_desc">透過Beker Pro音樂貝殼會記錄游泳動態數據紀錄並且有即時分析功能</p>
            <p class="image_desc">(圖片提供：快樂島)</p>
          </div>
          <div class="image-container">
            <img :src="image3" class="image" alt="">
            <p class="image_desc">音樂貝殼可以根據轉身計算趟數換算距離</p>
            <p class="image_desc">(圖片提供：快樂島)</p>
          </div>
          <div class="image-container">
            <img :src="image4" class="image" alt="">
            <p class="image_desc">可以計算划手次數</p>
            <p class="image_desc">(圖片提供：快樂島)</p>
          </div>
          <div class="image-container">
            <img :src="image5" class="image" alt="運動完畢音樂貝殼會將數據同步到手機APP，讓參與者可以隨時追蹤數據資料">
            <p class="image_desc">運動完畢音樂貝殼會將數據同步到手機APP，讓參與者可以隨時追蹤數據資料</p>
            <p class="image_desc">(圖片提供：快樂島)</p>
          </div>
        </div>
        <div class="content-section">
          <div class="section-h3">數據公益模式</div>
          <div class="content-desc">
            透過快樂島主力商品音樂貝殼，新潮的骨傳導技術打造適合各種運動使用的智慧語音運動穿戴設備，取得相關游泳運動數據後，包括滑水次數、划距、划頻…等數據，收集了全台各年齡層、各縣市的游泳愛好者於25公尺和50公尺標準泳池的各項統計數字，可以利用此數據分析用戶各項游泳數據型態，協助教練規劃訓練課程，或者是找出游泳強度與時間對於心肺功能提升的對應關係，用以開發相關產品。
          </div>
        </div>
        <div class="content-section">
          <div class="section-h3">公益展望</div>
          <div class="content-desc">
            建立游泳數據與健康體適能關係模型，包含游泳強度與時間對於心肺功能提升的對應關係，以及游泳改善睡眠品質的數據分析模型，可提供國人做為心肺提升訓練以及改善睡眠品質的運動參考方向。 
          </div>
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'
export default {
  name: 'UseCase11105',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/sample_5.png'),
      image1: require('@/assets/images/sample/swim_1.png'),
      image2: require('@/assets/images/sample/swim_2.png'),
      image3: require('@/assets/images/sample/swim_3.png'),
      image4: require('@/assets/images/sample/swim_4.png'),
      image5: require('@/assets/images/sample/swim_5.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple5-container{
  .desk-pad-view{
    @include hidden-by-phone;
  }
  .phone-view{
    @include hidden-by-desk;
    @include hidden-by-pad;
  }
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
    }
    .image_desc{
      text-align: center;
    }
    .image-100 {
      width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
  .content-section{
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
